#Appbar, #Toolbar {
    width: 100%; /* Default to full width */
    height: 60px; /* Smallest height by default */
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 10px; /* Smallest padding for mobile */
}

#Appbar {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.main-content {
    flex: 1;
    width: 100%;
    margin: 0 auto;
}

/* Tablet (medium screens) */
@media (min-width: 768px) {
    #Appbar, #Toolbar {
        height: 70px;
        padding: 0 15px;
    }

    .main-content {
        flex: 1;
        width: 75vw;
        margin: 0 auto;
    }
}

/* Desktop (large screens) */
@media (min-width: 1200px) {
    #Appbar, #Toolbar {
        max-width: 1200px;
        height: 80px;
        padding: 0 20px;
    }

    .main-content {
        flex: 1;
        width: 70vw;
        max-width: 1200px;
        margin: 0 auto;
    }
}

/* Widescreen (extra-large screens) */
@media (min-width: 2000px) {
    #Appbar, #Toolbar {
        max-width: 1800px;
        height: 90px;
        padding: 0 30px;
    }

    .main-content {
        flex: 1;
        width: 60vw;
        max-width: 1800px;
        margin: 0 auto;
    }
}


#local-beat-logo {
    width: 100px;
    height: 30px;
    box-sizing: border-box;
    font-family: "Raleway-SemiBold", "Raleway SemiBold", "Raleway", sans-serif;
    font-weight: 650;
    text-align: left;
    line-height: normal;
}

#logo {
    width: 36px;
    height: 42px;
    box-sizing: border-box;
}

.MuiButton-root.event-card-button {
    width: 120px;
    height: 34px;
    padding: 1px;
    border-radius: 64px;
    box-sizing: border-box;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.8px;
}

.MuiTypography-root.event-card-date {
    box-sizing: border-box;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: normal;
}

.MuiTypography-root.event-card-location {
    box-sizing: border-box;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: normal;
}

.MuiTypography-root.event-card-title {
    box-sizing: border-box;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
}

.MuiBox-root.event-card {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.2s ease, filter 0.2s ease;
    transform-origin: center;
    @media (min-width: 600px) {
        &:hover {
            transform: scale(1.01);
        }
    };
    @media (max-width: 599px) {
        flex-direction: column;
        &:active, &:focus, &:hover {
            transform: scale(0.98);
            filter: brightness(1.05);
        }

        &:focus-visible {
            outline: 2px solid;
            transform: scale(1);
        }
    };
}

.event-card-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 6px;
    box-sizing: border-box;
}


#event-details-datetime {
    height: 21px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 700;
    color: #c24444;
    text-align: left;
    line-height: normal;
}

.MuiTypography-root.clickable-text {
    margin-bottom: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-weight: 500;
    line-height: normal;
}

.MuiTypography-root.clickable-text:hover {
    text-decoration: none;
    opacity: 0.8;
}

.MuiCard-root.details-card {
    padding: 2px 2px 2px 2px;
    background-color: rgba(234, 234, 234, 0.5);
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
}


